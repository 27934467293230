import SysFetch from "../../fetch";
import qs from "qs";

const OrderRequest = {
  searchCustomerByUsername: (email) => {
    return SysFetch.get(
      `customer?pageSize=9999999&${qs.stringify({ email }, { encode: false })}`
    );
  },
  getOrders: ({
    page,
    pageSize,
    code,
    orderNumber,
    servicePackageCode,
    customerID,
    status,
  }) => {
    return SysFetch.post(`orders/admin`, {
      page,
      pageSize,
      code,
      orderNumber,
      servicePackageCode,
      customerID,
      status,
    });
  },
  getOrderById: (id) => SysFetch.get(`orders/admin/${id}`),
  getPaymentActivities: (id, body) =>
    SysFetch.get(
      `payment-activity/${id}?${qs.stringify(body, {
        encode: false,
      })}`
    ),
  checkYouTubeSubscriberOrders: () =>
    SysFetch.get(`orders/check-youtube-subscriber-orders-status`),
  completeOrder: (id) => SysFetch.put(`orders/${id}/complete`),
  refundOrder: (id, quantity) =>
    SysFetch.post(
      `orders/youtube-order/${id}?${qs.stringify(
        { quantity },
        { encode: false }
      )}`
    ),
  reSendOrder: (id, quantity) =>
    SysFetch.post(
      `orders/youtube-resend/${id}?${qs.stringify(
        { quantity },
        { encode: false }
      )}`
    ),
  updateOrderStatus: (id, status) =>
    SysFetch.put(`orders/${id}/updateStatus`, { status }),

  handleRefundOrder: (data) => SysFetch.post(`orders/refund`, data),
  resendAllProcessing: (data) =>
    SysFetch.post(`orders/resend-all-processing`, data),
  refreshOrderNumber: (orderId) =>
    SysFetch.put(`orders/refresh-orderNumber/${orderId}`),
  reSendCompletedOrder: (id) =>
    SysFetch.post(`orders/resend-local-running-order`, {
      _id: id,
    }),
};

export default OrderRequest;
