import React from "react";
import "./style.css";
import UserInfo from "./UserInfo";
import { useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  return (
    <div className="header-container">
      <div
        className="flex items-center hoverLogo"
        onClick={() => navigate("/services/dashboard")}
      >
        <img
          alt="admin"
          className="h-[50px] w-[50px]"
          src={require("../../images/logo.png")}
        />
        <div className="px-2">
          <h1 className="font-bold text-3xl text-black/70 text-center">
            <span className="text-[#FF8900]">Top</span>click
          </h1>
          <p className="text-[#FF8900] text-sm">Click to the top</p>
        </div>
      </div>
      <UserInfo />
    </div>
  );
}

export default Header;
