// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes table-tr-an {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.table-container {
  border-spacing: 0px;
  width: 100%;
  transition-duration: 0.5s;
}
.table-td-base {
  border: 1px solid #f7f9fa;
  padding: 4px;
  text-align: center;
  animation: table-tr-an 0.5s ease-in-out;
  transition-duration: 0.5s;
}
.table-tr {
  transition-duration: 0.5s;
  cursor: pointer;
}
.table-tr:hover {
  background-color: #cccccc30;
}

.table-td-head {
  color: var(--Dark-100, #72777a);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
}

.table-no-data {
  color: #72777a;
  padding: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/base/table/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EACvC,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["@keyframes table-tr-an {\n  from {\n    opacity: 0.1;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.table-container {\n  border-spacing: 0px;\n  width: 100%;\n  transition-duration: 0.5s;\n}\n.table-td-base {\n  border: 1px solid #f7f9fa;\n  padding: 4px;\n  text-align: center;\n  animation: table-tr-an 0.5s ease-in-out;\n  transition-duration: 0.5s;\n}\n.table-tr {\n  transition-duration: 0.5s;\n  cursor: pointer;\n}\n.table-tr:hover {\n  background-color: #cccccc30;\n}\n\n.table-td-head {\n  color: var(--Dark-100, #72777a);\n  font-size: 14px;\n  line-height: 16px;\n  text-transform: uppercase;\n  text-align: center;\n}\n\n.table-no-data {\n  color: #72777a;\n  padding: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
