import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  search: "",
  searchNumberOrder: "",
  orderDetail: undefined,
  customerList: [],
  currentCustomer: undefined,
  servicesSearch: undefined,
  status: undefined,
  isLoading: false,
  isLoadingResend: false,
};

const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setLoadingResend: (state, { payload }) => {
      state.isLoadingResend = payload;
    },
    searchCustomerByUsername: (state, { payload }) => {},
    setCustomerList: (state, { payload }) => {
      state.customerList = payload;
    },
    getOrders: () => {},
    setOrders: (state, { payload }) => {
      state.orderList = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.search = payload;
    },
    setSearchNumberOrder: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.searchNumberOrder = payload;
    },
    setServicesSearch: (state, { payload }) => {
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.servicesSearch = payload;
    },
    getOrderById: (state, { payload }) => {},
    setOrderDetail: (state, { payload }) => {
      state.orderDetail = payload;
    },
    setCurrentCustomer: (state, { payload }) => {
      state.currentCustomer = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    resetSession: (state) => {
      state.search = "";
      state.searchNumberOrder = "";
      state.orderList = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    block: (state, { payload }) => {},
    handleRefundOrder: () => {},
    restore: (state, { payload }) => {},
    checkYouTubeSubscriberOrders: () => {},
    completeOrder: () => {},
    refundOrder: () => {}, // Thêm action refundOrder
    reSendOrder: () => {}, // Thêm action reSendOrder
    reSendCompletedOrder: () => {},
    updateOrderStatus: () => {},
    resendAllProcessing: () => {},
    refreshOrderNumber: () => {},
  },
});

const OrderReducer = OrderSlice.reducer;
export default OrderReducer;

export const OrderActions = OrderSlice.actions;
export const OrderSelectors = {
  orderList: (state) => state.order.orderList,
  pagination: (state) => state.order.pagination,
  search: (state) => state.order.search,
  searchNumberOrder: (state) => state.order.searchNumberOrder,
  orderDetail: (state) => state.order.orderDetail,
  customerList: (state) => state.order.customerList,
  currentCustomer: (state) => state.order.currentCustomer,
  servicesSearch: (state) => state.order.servicesSearch,
  status: (state) => state.order.status,
  loading: (state) => state.order.isLoading,
  loadingResend: (state) => state.order.isLoadingResend,
};
