import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MST from "..";
import { isValidPath } from "../../app/utils/format";

const AllowedPages = ({ children }) => {
  const location = useLocation();
  const userInfo = useSelector((state) => state.login.userInfo);
  const isAllowedPage = useMemo(() => {
    return userInfo?.allowedPages?.some((p) =>
      isValidPath(p, location.pathname)
    );
  }, [location.pathname, userInfo?.allowedPages]);
  if (userInfo?.role == "admin") {
    return <>{children}</>;
  }
  if (isAllowedPage) {
    return <>{children}</>;
  }
  return (
    <MST.Container>
      <div className="flex items-center justify-center h-full px-4">
        <div className="w-full max-w-md p-8 text-center ">
          <div className="mb-6">
            <img src="/images/block-page.webp" alt="" className="rounded-lg" />
          </div>
          <h2 className="mb-4 text-2xl font-bold text-gray-800">
            Không đủ quyền truy cập
          </h2>
          <p className="mb-8 text-gray-600">
            Xin lỗi, bạn không có quyền truy cập vào trang này. Vui lòng liên hệ
            với quản trị viên để được hỗ trợ.
          </p>
        </div>
      </div>
    </MST.Container>
  );
};

export default AllowedPages;
