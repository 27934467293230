// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap {
  word-break: break-word; /* Ngắt từ nếu từ quá dài */
  overflow-wrap: break-word; /* Ngắt dòng tại vị trí hợp lý cho chuỗi dài */
  white-space: normal;
  width: 100%;
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/service-groups/style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,2BAA2B;EACnD,yBAAyB,EAAE,8CAA8C;EACzE,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".wrap {\n  word-break: break-word; /* Ngắt từ nếu từ quá dài */\n  overflow-wrap: break-word; /* Ngắt dòng tại vị trí hợp lý cho chuỗi dài */\n  white-space: normal;\n  width: 100%;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
