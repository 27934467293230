import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SupplieActions,
  SupplieSelectors,
} from "../../../app/services/supplies/supplies.slice";
import { ServiceImportActions, ServiceImportSelectors } from "../../../app/services/service-import/service-import.slice";

const DownIcon = () => (
  <svg viewBox="0 0 320 512" width={20} height={20}>
    <path
      fill="currentColor"
      d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
    />
  </svg>
);

const FilterByPlatform = ({
  label,
  placeholder,
  disabled = false,
}) => {
    const {platform,search} = useSelector(ServiceImportSelectors.filter);
    const options = useSelector(ServiceImportSelectors.serviceImportList);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(platform);
  const [hoveredItem, setHoveredItem] = useState(null);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    dispatch(SupplieActions.getAllSupplie());
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleStatusChange = (status) => {
    dispatch(ServiceImportActions.setFilter({search,platform:status == 'all' ? '' : status}))
    setSelectedItem(status);
    setIsOpen(false);
  };

  const getOptionStyle = (optionValue) => {
    const baseStyle = {
      padding: "10px 12px",
      cursor: "pointer",
      transition: "background-color 0.2s",
    };

    if (selectedItem === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f0f0f0",
      };
    }

    if (hoveredItem === optionValue) {
      return {
        ...baseStyle,
        backgroundColor: "#f8f8f8",
      };
    }

    return baseStyle;
  };

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  return (
    <div ref={ref} className={`relative w-full my-[10px]`}>
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          padding: "0 5px",
          backgroundColor: "white",
          fontSize: "12px",
          color: isOpen ? "#ff8900" : "#72777A",
          zIndex: 1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "42px",
          boxSizing: "border-box",
          padding: "0 12px",
          borderRadius: "8px",
          border: `1px solid ${isOpen ? `#ff8900` : `#e3e5e5`}`,
          transition: "all 0.2s",
          cursor: "pointer",
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? "0.3" : 1,
        }}
        onClick={() => {
          if (!disabled) {
            setIsOpen((_prev) => !_prev);
          }
        }}
      >
        <div
          style={{
            flex: 1,
            fontSize: "14px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          className="capitalize"
        >
          {['all',...Object.keys(options||{})].find((option) => option === selectedItem) || placeholder}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: isOpen ? "#ff8900" : "#e3e5e5",
            transition: "all 0.2s",
          }}
        >
          <DownIcon />
        </div>
      </div>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            zIndex: 10,
            maxHeight: "220px",
            overflowY: "auto",
          }}
          className="border border-gray-200"
        >
          {['all',...Object.keys(options||{})].map((option) => (
            <div
              className="p-2 capitalize cursor-pointer"
              key={option}
              onClick={() => handleStatusChange(option)}
              onMouseEnter={() => setHoveredItem(option)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterByPlatform;
