import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { SupplieActions } from "./supplies.slice";
import { toast } from "react-toastify";
import SupplieRequest from "./supplies.request";

export default function* SupplieSaga() {
  yield takeLatest(SupplieActions.createSupplie, createSupplie);
  yield takeLatest(SupplieActions.getAllSupplie, getAllSupplie);
  yield takeLatest(SupplieActions.getSupplieById, getSupplieById);
  yield takeLatest(SupplieActions.updateSupplie, updateSupplie);
  yield takeLatest(SupplieActions.deleteSupplie, deleteSupplie);
}
  function* createSupplie({ payload }) {
    try {
      const {data,onSuccess} =payload
      yield put(SupplieActions.setIsSubmitting(true));
      const response = yield call(SupplieRequest.createSupplie, data);
      if (response.success) {
        toast.success("Thêm supplie thành công");
        onSuccess && onSuccess();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      yield put(SupplieActions.setError(error?.response?.data.message));
      toast.error(error?.response?.data.message);
    } finally {
      yield put(SupplieActions.setIsSubmitting(false));
    }
  }
  
  function* getAllSupplie() {
    try {
      yield put(SupplieActions.setIsFetching(true));
      const response = yield call(SupplieRequest.getAllSupplies);
      if (response.success) {
        yield put(SupplieActions.setSupplieList(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      yield put(SupplieActions.setError(error?.response?.data.message));
      toast.error(error?.response?.data.message);
    } finally {
      yield put(SupplieActions.setIsFetching(false));
    }
  }
  
  function* getSupplieById({ payload }) {
    try {
      yield put(SupplieActions.setIsFetchingDetail(true));      
      const response = yield call(SupplieRequest.getSupplieById, payload);
      if (response.success) {
        yield put(SupplieActions.setSupplieDetail(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      yield put(SupplieActions.setError(error?.response?.data.message));
      toast.error(error?.response?.data.message);
    } finally {
      yield put(SupplieActions.setIsFetchingDetail(false));
    }
  }
  
  function* updateSupplie({ payload }) {
    try {
      const {id,data, onSuccess}=payload
      yield put(SupplieActions.setIsSubmitting(true));
      const response = yield call(SupplieRequest.updateSupplie, id, data);
      if (response.success) {
        toast.success("Cập nhật supplie thành công");
        onSuccess && onSuccess();
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      yield put(SupplieActions.setError(error?.response?.data.message));
      toast.error(error?.response?.data.message);
    } finally {
      yield put(SupplieActions.setIsSubmitting(false));
    }
  }
  
  function* deleteSupplie({ payload }) {
    try {
      const {id, onSuccess}=payload
      yield put(SupplieActions.setIsSubmitting(true));
      const response = yield call(SupplieRequest.deleteSupplie, id);
      if (response.success) {
        onSuccess && onSuccess();
        toast.success("Xóa bên cung cấp thành công");
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      yield put(SupplieActions.setError(error?.response?.data.message));
      toast.error(error?.response?.data.message);
    } finally {
      yield put(SupplieActions.setIsSubmitting(false));
    }
  }




