import { call, delay, put, takeLeading } from "redux-saga/effects";
import { ServicePricesActions } from "./serivce.slice";
import ServicePricesRequest from "./service.request";

function* ServicePricesSaga() {
  yield takeLeading(
    ServicePricesActions.getDataServiceGroupRequest,
    getDataServiceGroup
  );
  yield takeLeading(
    ServicePricesActions.getServicePricesRequest,
    getServicesPrices
  );
  yield takeLeading(
    ServicePricesActions.getExportDataServicePricesRequest,
    getExportDataServicePricesRequest
  );
}

export default ServicePricesSaga;

function* getDataServiceGroup() {
  try {
    yield delay(100);
    const rs = yield call(ServicePricesRequest.getDataServiceGroups);
    if (rs?.success) {
      yield put(
        ServicePricesActions.getDataServiceGroupSuccess(rs?.data?.serviceGroups)
      );
    } else {
      yield put(ServicePricesActions.getServicePricesFailure(rs?.message));
    }
  } catch (error) {
    console.log("error on service group saga", error);
    yield put(ServicePricesActions.getServicePricesFailure(error?.message));
  }
}

function* getServicesPrices({ payload }) {
  try {
    yield delay(100);
    const rs = yield call(ServicePricesRequest.getServicePrices, payload);
    if (rs?.success) {
      yield put(
        ServicePricesActions.getServicePricesSuccess(
          rs?.data || {
            services: [],
            pagination: {
              page: 1,
              pageSize: 10,
              total: 0,
              totalPage: 0,
            },
          }
        )
      );
    } else {
      yield put(ServicePricesActions.getServicePricesFailure(rs?.message));
    }
  } catch (error) {
    console.log("error on service prices saga", error);
    yield put(ServicePricesActions.getServicePricesFailure(error?.message));
  }
}

function* getExportDataServicePricesRequest({ payload }) {
  try {
    yield delay(100);
    const rs = yield call(
      ServicePricesRequest.getServicePricesExportData,
      payload
    );
    if (rs?.success) {
      yield put(
        ServicePricesActions.getExportDataServicePricesSuccess({
          exportData: rs?.data?.services || [],
        })
      );
    } else {
      yield put(
        ServicePricesActions.getExportDataServicePricesFailure(rs?.message)
      );
    }
  } catch (error) {
    console.log("error on service prices saga", error);
    yield put(
      ServicePricesActions.getExportDataServicePricesFailure(error?.message)
    );
  }
}
