import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  isLoading: false,
  error: "",
  dataServiceGroup: [],
  isLoadingServiceGroup: false,
  errorServiceGroup: "",
  exportData: [],
  isLoadingExportData: false,
  errorExportData: "",
};

const ServicePricesSlice = createSlice({
  name: "service-prices",
  initialState,
  reducers: {
    getServicePricesRequest: (state, { payload }) => {
      state.isLoading = true;
      state.error = "";
    },
    getServicePricesSuccess: (state, { payload }) => {
      state.services = payload.services;
      state.pagination = payload.pagination;
      state.isLoading = false;
      state.error = "";
    },
    getServicePricesFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    resetServicePrices: (state) => {
      state.services = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
      state.isLoading = false;
      state.error = "";
    },
    getDataServiceGroupRequest: (state) => {
      state.isLoadingServiceGroup = true;
      state.errorServiceGroup = "";
    },
    getDataServiceGroupSuccess: (state, { payload }) => {
      state.dataServiceGroup = payload;
      state.isLoadingServiceGroup = false;
      state.errorServiceGroup = "";
    },
    getDataServiceGroupFailure: (state, { payload }) => {
      state.isLoadingServiceGroup = false;
      state.errorServiceGroup = payload;
    },
    clearError: (state) => {
      state.error = "";
      state.errorServiceGroup = "";
    },
    getExportDataServicePricesRequest: (state, { payload }) => {
      state.isLoadingExportData = true;
      state.errorExportData = "";
    },
    getExportDataServicePricesSuccess: (state, { payload }) => {
      state.exportData = payload.exportData;
      state.isLoadingExportData = false;
      state.errorExportData = "";
    },
    getExportDataServicePricesFailure: (state, { payload }) => {
      state.isLoadingExportData = false;
      state.errorExportData = payload;
    },
  },
});

const ServicePricesReducer = ServicePricesSlice.reducer;
export default ServicePricesReducer;

export const ServicePricesActions = ServicePricesSlice.actions;
export const ServicePricesSelectors = {
  services: (state) => state.servicePrices.services,
  pagination: (state) => state.servicePrices.pagination,
  isLoading: (state) => state.servicePrices.isLoading,
  error: (state) => state.servicePrices.error,
  dataServiceGroup: (state) => state.servicePrices.dataServiceGroup,
  isLoadingServiceGroup: (state) => state.servicePrices.isLoadingServiceGroup,
  errorServiceGroup: (state) => state.servicePrices.errorServiceGroup,
  exportData: (state) => state.servicePrices.exportData,
  isLoadingExportData: (state) => state.servicePrices.isLoadingExportData,
  errorExportData: (state) => state.servicePrices.errorExportData,
};
