import SysFetch from "../../fetch";
import qs from "qs";

const ServicePricesRequest = {
  getServicePrices: (body) => {
    return SysFetch.post(
      `service-packages/pricing?${qs.stringify(body, { encode: false })}`
    );
  },
  getDataServiceGroups: () => {
    return SysFetch.get(`/service-groups`);
  },
  getServicePricesExportData: (body) => {
    return SysFetch.post(
      `service-packages/pricing?${qs.stringify(body, { encode: false })}`
    );
  },
};

export default ServicePricesRequest;
