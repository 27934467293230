import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import store from "../app/services/store";

import { setConfigAxios } from "../app/fetch";
import CONST from "../app/services/const";
import InitComponent from "../components/InitComponent";
import ChangePasswordPage from "./change-password/ChangePasswordPage";
import CustomerDetailPage from "./customer-detail/CustomerDetailPage";
import CustomerPage from "./customer/CustomerPage";
import DashBoardPage from "./dashboard/DashBoardPage";
import GeneralSettingPage from "./general-setting/GeneralSettingPage";
import HomePage from "./home/HomePage";
import KeywordEditPage from "./keyword-edit/KeywordEditPage";
import KeywordPage from "./keyword/KeywordPage";
import LocalServerCreatePage from "./local-server-setting/LocalServerCreatePage";
import LocalServicePage from "./local-server-setting/LocalServerPage";
import LoginPage from "./login/LoginPage";
import NewsCreatePage from "./news-create/NewsCreatePage";
import NewsEditPage from "./news-edit/NewsEditPage";
import NewsPage from "./news/NewsPage";
import NotificationCreatePage from "./notification-create/NotificationCreatePage";
import NotificationEditPage from "./notification-edit/NotificationEditPage";
import NotificationPage from "./notifications/NotificationPage";
import OrderDetailPage from "./order-detail/OrderDetailPage";
import OrderPage from "./order/OrderPage";
import PageNotFound from "./page-not-found/PageNotFoundPage";
import PartnerSettingPage from "./partner-setting/PartnerSettingPage";
import PaymentSettingPage from "./payment-setting/PaymentSettingPage";
import ServiceCreatePage from "./service-create/ServiceCreatePage";
import ServiceEditPage from "./service-edit/ServiceEditPage";
import ServicePage from "./service/ServicePage";
import SupportAccountCreatePage from "./support-account-setting/SupportAccountCreatePage";
import SupportAccountPage from "./support-account-setting/SupportAccountPage";
import TopicEditPage from "./topic-edit/TopicEditPage";
import TopicPage from "./topic/TopicPage";
import VoucherCreatePage from "./voucher-create/VoucherCreatePage";
import VoucherEditPage from "./voucher-edit/VoucherEditPage";
import VoucherHistoryPage from "./voucher-history/VoucherHistoryPage";
import VoucherPage from "./voucher/VoucherPage";
import ServiceGroupsPage from "./service-groups/ServiceGroupsPage";

import ApiKeyPage from "./api-key/ApiKeyPage";
import ContentPromptPage from "./content-prompt/ContentPromptPage";
import AddPromotionForm from "./promotions/PromotionAdd";
import PromotionPage from "./promotions/PromotionPage";
import VisitPage from "./visit/VisitPage";
import NewsAccountPage from "./news-account-setting/NewsAccountPage";
import NewsAccountCreatePage from "./news-account-setting/NewsAccountCreatePage";
import ServicePricesPage from "./service-prices/ServicePricesPage";
import AuthProvider from "../components/provider/AuthProvider";
import AllowedPages from "../components/provider/AllowedPages";
import SuppliePage from "./suppliers/SuppliePage";
import ServiceImport from "./service-import/ServiceImport";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "services",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "dashboard",
            element: (
              <AllowedPages>
                {" "}
                <DashBoardPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "visit",
            element: (
              <AllowedPages>
                <VisitPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services",
            element: (
              <AllowedPages>
                <ServicePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services-groups",
            element: (
              <AllowedPages>
                <ServiceGroupsPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services-prices",
            element: (
              <AllowedPages>
                <ServicePricesPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services/create",
            element: (
              <AllowedPages>
                <ServiceCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services/import",
            element: (
              <AllowedPages>
                <ServiceImport />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "services/edit/:id",
            element: (
              <AllowedPages>
                <ServiceEditPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          //Nhà cung cấp
          {
            path: "supplier",
            element: (
              <AllowedPages>
                <SuppliePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "customers/:id",
            element: (
              <AllowedPages>
                <CustomerDetailPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "customers",
            element: (
              <AllowedPages>
                <CustomerPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "orders",
            element: (
              <AllowedPages>
                {" "}
                <OrderPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "orders/:id",
            element: (
              <AllowedPages>
                <OrderDetailPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications",
            element: (
              <AllowedPages>
                <NotificationPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications/create",
            element: (
              <AllowedPages>
                <NotificationCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "notifications/edit/:id",
            element: (
              <AllowedPages>
                <NotificationEditPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/general",
            element: (
              <AllowedPages>
                <GeneralSettingPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/payment",
            element: (
              <AllowedPages>
                <PaymentSettingPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/partner",
            element: (
              <AllowedPages>
                <PartnerSettingPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server",
            element: (
              <AllowedPages>
                <LocalServicePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server/create",
            element: (
              <AllowedPages>
                <LocalServerCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/local-server/edit/:id",
            element: (
              <AllowedPages>
                <LocalServerCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/api-key",
            element: (
              <AllowedPages>
                <ApiKeyPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account",
            element: (
              <AllowedPages>
                <SupportAccountPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account",
            element: (
              <AllowedPages>
                <NewsAccountPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account/create",
            element: (
              <AllowedPages>
                <NewsAccountCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/news-account/edit/:id",
            element: (
              <AllowedPages>
                <NewsAccountCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account/create",
            element: (
              <AllowedPages>
                <SupportAccountCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/support-account/edit/:id",
            element: (
              <AllowedPages>
                {" "}
                <SupportAccountCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers",
            element: (
              <AllowedPages>
                <VoucherPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers/create",
            element: (
              <AllowedPages>
                <VoucherCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "vouchers/edit/:id",
            element: (
              <AllowedPages>
                <VoucherEditPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "voucher-histories",
            element: (
              <AllowedPages>
                <VoucherHistoryPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "voucher-histories/:id",
            element: (
              <AllowedPages>
                <VoucherHistoryPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "news",
            element: (
              <AllowedPages>
                <NewsPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "news/create",
            element: (
              <AllowedPages>
                <NewsCreatePage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "news/edit/:id",
            element: (
              <AllowedPages>
                <NewsEditPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "content-prompt",
            element: (
              <AllowedPages>
                <ContentPromptPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions",
            element: (
              <AllowedPages>
                <PromotionPage />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions/create",
            element: (
              <AllowedPages>
                <AddPromotionForm />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
          {
            path: "promotions/:id/edit",
            element: (
              <AllowedPages>
                <AddPromotionForm />
              </AllowedPages>
            ),
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "keyword",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "dashboard",
            element: <DashBoardPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/general",
            element: <GeneralSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/payment",
            element: <PaymentSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "settings/partner",
            element: <PartnerSettingPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "topics",
            element: <TopicPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "keywords",
            element: <KeywordPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "topics/edit/:id",
            element: <TopicEditPage />,
            errorElement: <PageNotFound />,
          },
          {
            path: "keywords/edit/:id",
            element: <KeywordEditPage />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: "system",
        errorElement: <PageNotFound />,
        children: [
          {
            path: "change-password",
            element: <ChangePasswordPage />,
            errorElement: <PageNotFound />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <PageNotFound />,
  },
]);

const accessToken = localStorage.getItem(CONST.STORAGE.ACCESS_TOKEN);
setConfigAxios(accessToken);

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <InitComponent />
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>
  );
}

export default App;
