import {
  delay,
  put,
  select,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import LoginRequest from "./login.request";
import { LoginActions, LoginSelectors } from "./login.slice";
import { toast } from "react-toastify";
import CONST from "../const";

function* LoginSaga() {
  yield takeLeading(LoginActions.checkLogin, checkLogin);
  yield takeLatest(LoginActions.changePassword, changePassword);
  yield takeLatest(LoginActions.getCurrentUser, getCurrentUser);
}

export default LoginSaga;

function* checkLogin({ payload }) {
  yield delay(500);
  const { body, onFail, onSuccess } = payload;

  try {
    const { success, message, data } = yield LoginRequest.checkLogin({ body });
    if (success) {
      onSuccess(data);
    } else {
      onFail && onFail(`${message}`);
    }
  } catch (error) {
    onFail && onFail(error);
  }
}
function* getCurrentUser({ payload }) {
  try {
    const { success, data } = yield LoginRequest.getCurrentUser();
    if (success) {
      localStorage.setItem(
        CONST.STORAGE.USER_INFO,
        JSON.stringify(data.admin || {})
      );
      yield put(LoginActions.setUserInfo(data.admin));
    }
  } catch (error) {
    console.log(error);
  }
}
function* changePassword({ payload }) {
  yield delay(300);
  const { body, onSuccess } = payload;

  try {
    const { success, message, data } = yield LoginRequest.changePassword({
      body,
    });

    if (success) {
      onSuccess();
      toast.success("Thay đổi mật khẩu thành công");
    } else {
      toast.error(message);
    }
  } catch (error) {}
}
