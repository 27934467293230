import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supplieList: [],
  supplieDetail: null,
  isFetching: false,
  isFetchingDetail: false,
  isSubmitting: false,
  error: null,
};

const SupplieSlice = createSlice({
  name: "supplie",
  initialState,
  reducers: {
    setIsFetching: (state, { payload }) => {
      state.isFetching = payload;
    },
    setIsFetchingDetail: (state, { payload }) => {
      state.isFetchingDetail = payload;
    },
    setIsSubmitting: (state, { payload }) => {
      state.isSubmitting = payload;
    },
    createSupplie: (state, { payload }) => {},
    updateSupplie: (state, { payload }) => {},
    deleteSupplie: (state, { payload }) => {},
    getAllSupplie: (state, { payload }) => {
    },
    getSupplieById: (state, { payload }) => {
    },
    setSupplieList: (state, { payload }) => {
      state.supplieList = payload;
    },
    setSupplieDetail: (state, { payload }) => {
      state.supplieDetail = payload;
    },
    resetSupplieDetail: (state) => {
      state.supplieDetail = null;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const SupplieActions = SupplieSlice.actions;
export const SupplieReducer = SupplieSlice.reducer;

export const SupplieSelectors = {
  supplieList: (state) => state.supplie.supplieList,
  supplieDetail: (state) => state.supplie.supplieDetail,
  isFetching: (state) => state.supplie.isFetching,
  isFetchingDetail: (state) => state.supplie.isFetchingDetail,
  isSubmitting: (state) => state.supplie.isSubmitting,
  error: (state) => state.supplie.error,
};
