import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ServiceImportActions,
    ServiceImportSelectors,
} from "../../../app/services/service-import/service-import.slice";
import MST from "../../../components";

const SearchService = ({ disabled = false }) => {
  const [keyword, setKeyword] = useState("");
  const { platform } = useSelector(ServiceImportSelectors.filter);
  const dispatch = useDispatch();
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      return () => clearTimeout(handler);
    }, [value, delay]);
    return debouncedValue;
  };

  const debouncedKeyword = useDebounce(keyword, 700);

  useEffect(() => {
    dispatch(ServiceImportActions.setFilter({ search: debouncedKeyword, platform }));
  }, [debouncedKeyword, dispatch, platform]);

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <MST.Input
      style={{
        maxHeight: "42px",
        width: "100%",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? "0.3" : 1,
      }}
      value={keyword}
      onChange={handleChange}
      placeholder="Tìm kiếm dịch vụ"
      disabled={disabled}
    />
  );
};

export default SearchService;
