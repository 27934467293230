import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import {
  DashBoardActions,
  DashBoardSelectors,
} from "../../app/services/dashboard/dashboard.slice";
import Modal from "../../components/base/modal/Modal";
import MST from "../../components";
import { formatCurrency } from "../../app/function";

const fillMissingDaysOrMonths = (data, month, year, filterType) => {
  if (filterType === "month") {
    const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
    const filledData = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const dayLabel = `Ngày ${i}`;
      const found = data.find((item) => item.date === dayLabel);
      filledData.push(
        found || {
          date: dayLabel,
          netRevenue: "0",
          grossRevenue: "0",
          totalPendingOrders: 0,
          totalProcessingOrders: 0,
          totalRunningOrders: 0,
          totalCompletedOrders: 0,
          totalCancelledOrders: 0,
          totalPartiallyCancelledOrders: 0,
          totalPendingQuantity: 0,
          totalProcessingQuantity: 0,
          totalRunningQuantity: 0,
          totalCompletedQuantity: 0,
          totalCancelledQuantity: 0,
          totalPartiallyCancelledQuantity: 0,
        }
      );
    }
    return filledData;
  } else {
    const filledData = [];
    for (let i = 1; i <= 12; i++) {
      const monthLabel = `Tháng ${i}`;
      const found = data.find((item) => item.date === monthLabel);
      filledData.push(
        found || {
          date: monthLabel,
          netRevenue: "0",
          grossRevenue: "0",
          totalPendingOrders: 0,
          totalProcessingOrders: 0,
          totalRunningOrders: 0,
          totalCompletedOrders: 0,
          totalCancelledOrders: 0,
          totalPartiallyCancelledOrders: 0,
          totalPendingQuantity: 0,
          totalProcessingQuantity: 0,
          totalRunningQuantity: 0,
          totalCompletedQuantity: 0,
          totalCancelledQuantity: 0,
          totalPartiallyCancelledQuantity: 0,
        }
      );
    }
    return filledData;
  }
};

const DashBoardCalculateRevenue = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const calculateRevenue = useSelector(DashBoardSelectors.calculateRevenue);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [filterType, setFilterType] = useState("month");

  const formattedData = useMemo(() => {
    return calculateRevenue.map((item) => ({
      ...item,
      netRevenue: parseFloat(item.netRevenue),
      grossRevenue: parseFloat(item.grossRevenue),
      totalPendingOrders: parseInt(item.totalPendingOrders),
      totalProcessingOrders: parseInt(item.totalProcessingOrders),
      totalRunningOrders: parseInt(item.totalRunningOrders),
      totalCompletedOrders: parseInt(item.totalCompletedOrders),
      totalCancelledOrders: parseInt(item.totalCancelledOrders),
      totalPartiallyCancelledOrders: parseInt(
        item.totalPartiallyCancelledOrders
      ),
      totalPendingQuantity: parseInt(item.totalPendingQuantity),
      totalProcessingQuantity: parseInt(item.totalProcessingQuantity),
      totalRunningQuantity: parseInt(item.totalRunningQuantity),
      totalCompletedQuantity: parseInt(item.totalCompletedQuantity),
      totalCancelledQuantity: parseInt(item.totalCancelledQuantity),
      totalPartiallyCancelledQuantity: parseInt(
        item.totalPartiallyCancelledQuantity
      ),
    }));
  }, [calculateRevenue]);

  const filledRevenueData = useMemo(
    () =>
      fillMissingDaysOrMonths(
        formattedData,
        selectedMonth,
        selectedYear,
        filterType
      ),
    [formattedData, selectedMonth, selectedYear, filterType]
  );

  useEffect(() => {
    if (filterType === "month") {
      dispatch(
        DashBoardActions.getCalculateRevenue({
          year: selectedYear,
          month: selectedMonth,
        })
      );
    } else {
      dispatch(DashBoardActions.getCalculateRevenue({ year: selectedYear }));
    }
  }, [dispatch, selectedYear, selectedMonth, filterType]);

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value, 10));
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const formatYAxisTick = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(1)}B`;
    } else if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    }
    return value;
  };

  const ModalContent = (
    <div className="">
      <div className="title-pie mb-6">
        <div className="title-balance text-xl font-bold">
          Thống kê doanh thu đơn hàng
        </div>
      </div>
      <div className="flex space-x-4 mb-4">
        <select
          value={filterType}
          onChange={handleFilterTypeChange}
          className="p-2 border rounded"
        >
          <option value="month">Lọc theo tháng</option>
          <option value="year">Lọc theo năm</option>
        </select>
        {filterType === "month" && (
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="p-2 border rounded"
          >
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                Tháng {i + 1}
              </option>
            ))}
          </select>
        )}
        <select
          value={selectedYear}
          onChange={handleYearChange}
          className="p-2 border rounded"
        >
          {[...Array(moment().year() - 1990 + 1)].map((_, i) => {
            const year = moment().year() - 1 + i;
            return (
              <option key={year} value={year}>
                Năm {year}
              </option>
            );
          })}
        </select>
      </div>
      {filledRevenueData && filledRevenueData.length > 0 ? (
        <div style={{ width: "100%", height: 500, paddingBottom: "50px" }}>
          <ResponsiveContainer>
            <LineChart
              data={filledRevenueData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                fontSize={12}
                angle={-45}
                textAnchor="end"
                interval={0}
                tickMargin={10}
                minTickGap={20}
                height={60}
              />
              <YAxis
                yAxisId="left"
                tickFormatter={formatYAxisTick}
                fontSize={"12px"}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tickFormatter={formatYAxisTick}
                fontSize={"12px"}
              />
              <Tooltip
                formatter={(value, name) => {
                  if (name.includes("Doanh thu")) {
                    return [`${formatCurrency(value)}`, name];
                  }
                  return [value, name];
                }}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="netRevenue"
                name="Doanh thu sau khi trừ các khoản hủy"
                stroke="#4B4E51"
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="grossRevenue"
                name="Doanh thu ban đầu"
                stroke="#405189"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalPendingOrders"
                name="Đơn hàng đang chờ"
                stroke="#FEB705"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalPendingQuantity"
                name="Số lượng đặt đang chờ"
                stroke="#FEB705"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalProcessingOrders"
                name="Đơn hàng đang xử lý"
                stroke="#C69A39"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalProcessingQuantity"
                name="Số lượng đặt đang xử lý"
                stroke="#C69A39"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalRunningOrders"
                name="Đơn hàng đang chạy"
                stroke="#0088FE"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalRunningQuantity"
                name="Số lượng đặt đang chạy"
                stroke="#0088FE"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalCompletedOrders"
                name="Đơn hàng hoàn thành"
                stroke="#00C49F"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalCompletedQuantity"
                name="Số lượng đặt hoàn thành"
                stroke="#00C49F"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalCancelledOrders"
                name="Đơn hàng đã hủy"
                stroke="#FF0000"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalCancelledQuantity"
                name="Số lượng đặt đã hủy"
                stroke="#FF0000"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalPartiallyCancelledOrders"
                name="Đơn hàng đã hủy một phần"
                stroke="#DB521C"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="totalPartiallyCancelledQuantity"
                name="Số lượng đặt đã hủy một phần"
                stroke="#DB521C"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <p className="text-gray-500">Không có dữ liệu doanh thu để hiển thị.</p>
      )}
      <div className="flex justify-end mt-4">
        <MST.Button onClick={() => setOpen(false)} type="outlined">
          Đóng
        </MST.Button>
      </div>
    </div>
  );

  return (
    <Modal
      contentWidth={"1400px"}
      isShow={open}
      onHide={() => setOpen(false)}
      content={ModalContent}
    />
  );
};

export default DashBoardCalculateRevenue;
