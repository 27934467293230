import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  searchUsername: "",
  sortBalance: "asc",
  filter: {},
  searchCodeOrder: "",
  startDate: "",
  endDate: "",
  customerDetail: undefined,
  paymentActivities: [],
  paginationPaymentActivities: {
    page: 1,
    pageSize: 10,
    total: 0,
    totalPage: 0,
  },
  aggregate: [],
  currentListPage: 1,
};

const CustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCurrentListPage: (state, { payload }) => {
      state.currentListPage = payload;
    },
    recharge: (state, { payload }) => {},
    getCustomers: () => {},
    setCustomers: (state, { payload }) => {
      state.customerList = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setSearchUsername: (state, { payload }) => {
      state.searchUsername = payload;
    },
    setSortBalance: (state, { payload }) => {
      state.sortBalance = payload;
    },
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
    setSearchCodeOrder: (state, { payload }) => {
      state.searchCodeOrder = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    getCustomerById: (state, { payload }) => {},
    setCustomerDetail: (state, { payload }) => {
      state.customerDetail = payload;
    },
    getPaymentActivities: (state, { payload }) => {},
    setPaymentActivities: (state, { payload }) => {
      state.paymentActivities = payload;
    },
    setPaginationPaymentActivities: (state, { payload }) => {
      state.paginationPaymentActivities = payload;
    },
    resetSession: (state) => {
      state.searchUsername = "";
      state.customerList = [];
      state.pagination = {
        page: 1,
        pageSize: 10,
        total: 0,
        totalPage: 0,
      };
    },
    update: (state, { payload }) => {},
    block: (state, { payload }) => {},
    restore: (state, { payload }) => {},
    getAggregate: (state, { payload }) => {},
    setAggregate: (state, { payload }) => {
      state.aggregate = payload;
    },
  },
});

const CustomerReducer = CustomerSlice.reducer;
export default CustomerReducer;

export const CustomerActions = CustomerSlice.actions;
export const CustomerSelectors = {
  currentListPage: (state) => state.customer.currentListPage,
  customerList: (state) => state.customer.customerList,
  pagination: (state) => state.customer.pagination,
  searchUsername: (state) => state.customer.searchUsername,
  sortBalance: (state) => state.customer.sortBalance,
  filter: (state) => state.customer.filter,
  startDate: (state) => state.customer.startDate,
  endDate: (state) => state.customer.endDate,
  searchCodeOrder: (state) => state.customer.searchCodeOrder,
  customerDetail: (state) => state.customer.customerDetail,
  paymentActivities: (state) => state.customer.paymentActivities,
  paginationPaymentActivities: (state) =>
    state.customer.paginationPaymentActivities,
  aggregate: (state) => state.customer.aggregate,
};
