import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceImportList: {},
  resultFilterServiceImportList:{},
  filter:{
    search:'',
    platform:'',
  },
  supplieDetail: null,
  isFetching: false,
  isSubmitting: false,
  error: null,
};

const ServiceImportSlice = createSlice({
  name: "ServiceImport",
  initialState,
  reducers: {
    setIsFetching: (state, { payload }) => {
      state.isFetching = payload;
    },
    setIsFetchingDetail: (state, { payload }) => {
      state.isFetchingDetail = payload;
    },
    setIsSubmitting: (state, { payload }) => {
      state.isSubmitting = payload;
    },
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
    createServiceFromProvider: (state, { payload }) => {},
    getAllServiceByProviderId: (state, { payload }) => {
    },
    getAllServiceFilterSearchOrPlatForm:(state, { payload }) => {},
    setAllServiceFilterSearchOrPlatForm:(state, { payload }) => {
      state.resultFilterServiceImportList = payload;
    },
    setServiceImportList: (state, { payload }) => {
      state.serviceImportList = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const ServiceImportActions = ServiceImportSlice.actions;
export const ServiceImportReducer = ServiceImportSlice.reducer;

export const ServiceImportSelectors = {
  serviceImportList: (state) => state.serviceImport.serviceImportList,
  serviceImportFilterList: (state) => state.serviceImport.resultFilterServiceImportList,
  filter: (state) => state.serviceImport.filter,
  isFetching: (state) => state.serviceImport.isFetching,
  isSubmitting: (state) => state.serviceImport.isSubmitting,
  error: (state) => state.serviceImport.error,
};
