import { Close } from "@mui/icons-material";
import MST from "../../../components";
import Modal from "../../../components/base/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  SupplieActions,
  SupplieSelectors,
} from "../../../app/services/supplies/supplies.slice";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

const FormSupplie = ({ error, isOpen, onClose }) => {
  const supplieDetail = useSelector(SupplieSelectors.supplieDetail);
  const isFetchingDetail = useSelector(SupplieSelectors.isFetchingDetail);
  const isSubmitting = useSelector(SupplieSelectors.isSubmitting);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    url: "",
    key: "",
    balanceAlert: 0,
    name: "",
    errors: { url: "", key: "", name: "" },
  });

  useEffect(() => {
    if (supplieDetail) {
      setState((prev) => ({
        ...prev,
        url: supplieDetail.url,
        key: supplieDetail.key,
        balanceAlert: supplieDetail.balanceAlert,
        name: supplieDetail.name,
      }));
    }
    return () =>
      setState({
        url: "",
        key: "",
        balanceAlert: 0,
        name: "",
        errors: { url: "", key: "", name: "" },
      });
  }, [isOpen, supplieDetail]);

  const handleChange = (field, value) => {
    if (field === "url") {
      if (isUrlValid(value)) {
        const parsedUrl = new URL(value);
        setState((prev) => ({
          ...prev,
          url: value.trim(),
          name: prev.name && prev.name.includes(parsedUrl.hostname)
          ? prev.name
          : parsedUrl.hostname.concat(prev.name ? prev.name.replace(parsedUrl.hostname, "").trim() : ""),
          errors: { ...prev.errors, url: "" },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          url: value.trim(),
          name: "",
          errors: { ...prev.errors, url: "URL không hợp lệ" },
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        [field]: value,
        errors: { ...prev.errors, [field]: "" },
      }));
    }
  };

  const handleSubmit = () => {
    let errors = {};
    if (!state.url || !isUrlValid(state.url)) errors.url = "URL không hợp lệ";
    if (!state.key) errors.key = "Key không được để trống";
    if (!state.name) errors.name = "Tên không được để trống";

    if (Object.keys(errors).length > 0) {
      setState((prev) => ({ ...prev, errors }));
    } else {
      const data = { ...state, balanceAlert: state.balanceAlert || 0 };
      if (supplieDetail) {
        const payload = {
          id: supplieDetail?._id,
          data,
          onSuccess: () => {
            dispatch(SupplieActions.getAllSupplie());
            onClose();
          },
        };
        dispatch(SupplieActions.updateSupplie(payload));
      } else {
        dispatch(
          SupplieActions.createSupplie({
            data,
            onSuccess: () => {
              dispatch(SupplieActions.getAllSupplie());
              onClose();
            },
          })
        );
      }
    }
  };

  return (
    <Modal
      isShow={isOpen}
      onHide={onClose}
      content={
        <div className="bg-white p-2 w-[424px] max-w-full">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold text-gray-800">
              {supplieDetail ? "Cập nhật" : "Thêm"} nhà cung cấp
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <Close className="w-6 h-6" />
            </button>
          </div>
          {isFetchingDetail ? (
            <div className="flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="space-y-2">
              {["url", "key", "balanceAlert", "name"].map((field, idx) => (
                <div key={idx}>
                  <MST.Input
                    value={state[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                    placeholder={`${
                      field.charAt(0).toUpperCase() + field.slice(1)
                    } nhà cung cấp`}
                  />
                  {state.errors[field] && (
                    <p className="mt-1 text-sm text-red-500">
                      {state.errors[field]}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}

          {error && (
            <p className="mt-4 text-sm text-center text-red-500">{error}</p>
          )}
          <div className="flex justify-end mt-6">
            <MST.Button onClick={handleSubmit} isLoading={isSubmitting}>
              {isSubmitting ? <CircularProgress /> : "Lưu thay đổi"}
            </MST.Button>
          </div>
        </div>
      }
    />
  );
};

function isUrlValid(url) {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export default FormSupplie;
