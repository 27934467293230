import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import SyncProblemOutlinedIcon from "@mui/icons-material/SyncProblemOutlined";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import MST from "../../components";
import { useDispatch } from "react-redux";
import { OrderActions } from "../../app/services/order/order.slice";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: 2,
  boxShadow: 24,
  zIndex: 100,
  p: 4,
};

const themeOptions = [
  {
    name: "Đang chờ",
    icon: MoreTimeOutlinedIcon,
    status: "pending",
    color: "#FFF176",
  },
  {
    name: "Đang xử lý",
    icon: MoreTimeOutlinedIcon,
    status: "processing",
    color: "#F56323",
  },
  {
    name: "Đang chạy",
    icon: SyncOutlinedIcon,
    status: "running",
    color: "#81C784",
  },
  {
    name: "Hoàn thành",
    icon: AddTaskOutlinedIcon,
    status: "completed",
    color: "#4CAF50",
  },
  {
    name: "Đã huỷ",
    icon: CancelOutlinedIcon,
    status: "cancelled",
    color: "#EF5350",
  },
  {
    name: "Đã huỷ 1 phần",
    icon: SyncProblemOutlinedIcon,
    status: "partially_cancelled",
    color: "#FFB74D",
  },
];

const ActionStatus = ({ defaultStatus, orderId, quantity, startAt }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [quantityRefund, setQuantityRefund] = useState(quantity.toString());
  const [curentQty, setCurentQty] = useState(startAt);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    themeOptions.find((option) => option.status === defaultStatus) ||
      themeOptions[0]
  );
  const [tempOption, setTempOption] = useState(null);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const qtyRefund = parseInt(quantity) + startAt - curentQty;
    setQuantityRefund(qtyRefund.toString());
  }, [curentQty]);

  useEffect(() => {
    const newSelectedOption =
      themeOptions.find((option) => option.status === defaultStatus) ||
      themeOptions[0];
    setSelectedOption(newSelectedOption);
  }, [defaultStatus]);
  useEffect(() => {
    setQuantityRefund(quantity.toString());
  }, [quantity]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleOptionClick = (option) => {
    setTempOption(option);
    setIsOpen(false);
    setOpenModal(true);
    if (option.status === "partially_cancelled") {
      setQuantityRefund(quantity.toString());
      setErrorMessage("");
    }
  };
  const handleConfirm = () => {
    if (tempOption.status === "partially_cancelled") {
      handlePartialCancellation();
    } else if (tempOption.status === "completed") {
      dispatch(
        OrderActions.completeOrder({
          orderId,
          onSuccess: () => {
            setSelectedOption(tempOption);
          },
        })
      );
      setOpenModal(false);
    } else {      
      dispatch(
        OrderActions.updateOrderStatus({
          id: orderId,
          status: tempOption.status,
          onSuccess: () => {
            setSelectedOption(tempOption);
          },
        })
      );
      setOpenModal(false);
    }
    dispatch(OrderActions.getOrders());
  };

  const handlePartialCancellation = () => {
    const quantityRefundNumber = Number(quantityRefund);
    if (!Number.isInteger(quantityRefundNumber)) {
      setErrorMessage("Số lượng hoàn lại phải là số nguyên");
      return;
    }
    if (quantityRefundNumber <= 0 || quantityRefundNumber > quantity) {
      setErrorMessage("Số lượng hoàn lại không hợp lệ");
      return;
    }
    dispatch(
      OrderActions.refundOrder({
        id: orderId,
        quantity: quantityRefundNumber,
        onSuccess: () => {
          setSelectedOption(tempOption);
        },
      })
    );
    setOpenModal(false);
  };

  const renderModalContent = () => {
    return (
      <>
        <Typography variant="h6" component="h2">
          Xác nhận thay đổi trạng thái
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Bạn có chắc chắn muốn thay đổi trạng thái thành{" "}
          <span style={{ color: tempOption?.color }}>{tempOption?.name}</span>{" "}
          không?
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span style={{ color: tempOption?.color }}>Qty: {quantity}</span>
        </Typography>
        {tempOption?.status === "partially_cancelled" && (
          <Box sx={{ mt: 2 }}>
            <Typography>Nhập số lượng hiện tại:</Typography>
            <MST.Input
              type="number"
              placeholder="Nhập số lượng hiện tại"
              value={curentQty}
              onChange={(e) => setCurentQty(e.target.value)}
              // errorMessage={errorMessage}
              sx={{ mt: 1 }}
            />
            <Typography>Nhập số lượng hoàn lại:</Typography>
            <MST.Input
              type="number"
              placeholder="Nhập số lượng hoàn lại"
              value={quantityRefund}
              onChange={(e) => setQuantityRefund(e.target.value)}
              errorMessage={errorMessage}
              sx={{ mt: 1 }}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <DropdownButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        selectedOption={selectedOption}
      />
      {isOpen && (
        <DropdownMenu
          options={themeOptions}
          onOptionClick={handleOptionClick}
          currentStatus={selectedOption.status}
        />
      )}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={style}>
          {renderModalContent()}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setOpenModal(false)} sx={{ mr: 1 }}>
              Hủy
            </Button>
            <Button onClick={handleConfirm} variant="contained">
              Xác nhận
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

const DropdownButton = ({ isOpen, onClick, selectedOption }) => (
  <button
    onClick={onClick}
    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700"
  >
    <div className="flex items-center gap-1">
      <selectedOption.icon
        fontSize="small"
        style={{ color: selectedOption.color }}
      />
      <span
        style={{ color: selectedOption.color }}
        className="text-sm text-nowrap"
      >
        {selectedOption.name}
      </span>
    </div>
    <KeyboardArrowDownOutlinedIcon
      fontSize="small"
      className={`ml-1 transition delay-150 duration-300 ease-in-out ${
        isOpen ? "rotate-180" : "rotate-0"
      }`}
    />
  </button>
);

const DropdownMenu = ({ options, onOptionClick, currentStatus }) => (
  <div className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-[#1E1E1E] ring-1 ring-[#3A3A3A] ring-opacity-5">
    <div className="p-2 py-2" role="menu">
      {options.map((option) => {
        if (currentStatus === "running" && option.status === "completed") {
          return (
            <ThemeOption
              key={option.name}
              {...option}
              onClick={() => onOptionClick(option)}
            />
          );
        }
        return option.status !== currentStatus &&
          option.status !== "pending" &&
          option.status !== "completed" ? (
          <ThemeOption
            key={option.name}
            {...option}
            onClick={() => onOptionClick(option)}
          />
        ) : null;
      })}
    </div>
  </div>
);

const ThemeOption = ({ name, icon: Icon, color, onClick }) => (
  <div
    className="flex items-center gap-2 rounded-md px-4 py-2 hover:bg-[#262626] cursor-pointer transition-colors duration-200"
    role="menuitem"
    onClick={onClick}
  >
    <Icon fontSize="small" style={{ color }} />
    <span style={{ color }} className="text-sm">
      {name}
    </span>
  </div>
);

export default ActionStatus;
