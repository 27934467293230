import SysFetch from "../../fetch";

const ServiceImportRequest = {
  createServiceFromProvider: (body) => {
    return SysFetch.post("service-packages/import/from-provider", body);
  },
  getAllServiceByProviderId: ({providerId}) => {
    return SysFetch.get(`service-packages/import/${providerId}`);
  },
};

export default ServiceImportRequest;
