import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoginActions } from "../../app/services/login/login.slice";

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LoginActions.getCurrentUser());
  }, []);
  return <div>{children}</div>;
};

export default AuthProvider;
