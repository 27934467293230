import React from "react";
import MST from "../../components";
import { useState, useEffect, useCallback } from "react";
import Modal from "../../components/base/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceActions,
  ServiceSelectors,
} from "../../app/services/service/service.slice";
import ServiceGroupsDelete from "./ServiceGroups.Option";
import Editor from "../../components/base/editor/Editor";
import { isEmpty } from "lodash";
import PlugIcon from "../../images/icons/PlugIcon";
import LanguageSelect, {
  languages,
} from "../../components/table-create-service/LanguageSelect";
import "./style.css"

const ServiceGroupsPage = () => {
  const serviceGroupList = useSelector(ServiceSelectors.serviceGroup);
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [selectedLang, setSelectedLang] = useState("en");

  const onHide = () => setIsShow(false);
  const onShow = () => setIsShow(true);

  useEffect(() => {
    dispatch(ServiceActions.getCreateInfo());
  }, []);

  const thead = [
    {
      name: "STT",
      style: { width: 100 },
      className: "",
    },
    {
      name: "Nhóm Dịch Vụ",
      style: {
        width: 80,
        textAlign: "center",
      },
    },
    {
      name: "Mô Tả",
    },
    {
      name: "Thao Tác",
      style: {
        width: 120,
      },
    },
  ];

  const genRenderList = useCallback(() => {
    return serviceGroupList.map((x, index) => {
      return [
        { value: index + 1 },
        {
          value: x.name,
          style: {
            textAlign: "center",
          },
        },
        {
          value: (
            // <p className="text-justify break-words w-full max-w-full inline-block">
            <p className="text-justify wrap">
              {x?.description?.["en"]
                ?.replace(/<\/?[^>]+(>|$)/g, "")
                .replace(/&nbsp;/g, "")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&") ||
                x?.description?.["vi"]
                  ?.replace(/<\/?[^>]+(>|$)/g, "")
                  .replace(/&nbsp;/g, "")
                  .replace(/&lt;/g, "<")
                  .replace(/&gt;/g, ">")
                  .replace(/&amp;/g, "&") ||
                (typeof x.description === "object"
                  ? ""
                  : x?.description
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, "")
                      .replace(/&lt;/g, "<")
                      .replace(/&gt;/g, ">")
                      .replace(/&amp;/g, "&"))}
            </p>
            
          ),
        },
        {
          value: <ServiceGroupsDelete serviceGroup={x} key={x._id} />,
        },
      ];
    });
  }, [serviceGroupList]);

  useEffect(() => {
    return () => {
      setName("");
    };
  }, [isShow]);

  const onCreate = () => {
    dispatch(
      ServiceActions.createServiceGroup({
        name,
        description,
        onSuccess: (rs) => {
          dispatch(ServiceActions.getCreateInfo());
          setDescription("");
          onHide();
        },
      })
    );
  };

  const renderContent = (
    <div>
      <div className="modal-header">Thêm nhóm dịch vụ</div>
      <div className="modal-body">
        <div className="mb-8">Tên nhóm dịch vụ</div>
        <MST.Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nhập tên nhóm dịch vụ"
        />
        <div className="mb-8 mt-6">Mô tả nhóm dịch vụ</div>
        <LanguageSelect
          selectedLang={selectedLang}
          onSelectLanguage={setSelectedLang}
        />
        {languages.map(
          (lang) =>
            selectedLang === lang && (
              <Editor
                onBlur={(eventInfo, editor) => {
                  const data = editor?.getData();
                  const valueTrim = data?.trim();
                  if (isEmpty(data)) {
                    editor.setData("");
                    return;
                  }
                  setDescription((cur) => ({
                    ...cur,
                    [lang]: valueTrim,
                  }));
                }}
                data={
                  typeof description !== "object" && selectedLang === "en"
                    ? description || ""
                    : description?.[lang] || ""
                }
                height="150px"
                placeholder="Nhập mô tả"
                key={lang}
              />
            )
        )}
      </div>
      <div className="modal-footer">
        <div className="d-flex jc-between">
          <div />
          <MST.Button onClick={onCreate}>Xác nhận</MST.Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <MST.Container
        title="Danh sách nhóm dịch vụ"
        right={
          <div>
            <MST.Button onClick={onShow}>
              <span className="mr-3">
                <PlugIcon />
              </span>{" "}
              Thêm nhóm dịch vụ
            </MST.Button>
            <Modal
              isShow={isShow}
              onHide={onHide}
              content={renderContent}
              width={712}
            />
          </div>
        }
      >
        <MST.Table head={thead} body={genRenderList()} />
        <div className="customer-pagination"></div>
      </MST.Container>
    </div>
  );
};

export default ServiceGroupsPage;
