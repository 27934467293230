import React from "react";
import "./style.css";
import MST from "../..";
import { useContainerDimensions } from "../../../app/hooks";

function Container({ children, title, right }) {
  const { width, height } = useContainerDimensions(window);

  return (
    <div className="cn-container">
      {title ? (
        <div className="cn-title-container">
          <MST.Title>{title}</MST.Title>
          <div>{right}</div>
        </div>
      ) : (
        <></>
      )}
      <div
        className="cn-content"
        style={{
          maxHeight: title ? height - 185 : height - 130,
          overflow: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default Container;
