import { toast } from "react-toastify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ServiceImportRequest from "./service-import.request";
import {
  ServiceImportActions,
  ServiceImportSelectors,
} from "./service-import.slice";
import { findKeyword, normalizeText } from "../../utils/format";
const keywords = [
  "facebook",
  "youtube",
  "tiktok",
  "spotify",
  "google",
  "twitter",
  "telegram",
  "shopee",
  "linkedin",
  "instagram",
  "pinteres",
];
export default function* ServiceImportSaga() {
  yield takeLatest(
    ServiceImportActions.createServiceFromProvider,
    createServiceFromProvider
  );
  yield takeLatest(
    ServiceImportActions.getAllServiceByProviderId,
    getAllServiceByProviderId
  );
  yield takeLatest(
    ServiceImportActions.getAllServiceFilterSearchOrPlatForm,
    getAllServiceFilterSearchOrPlatForm
  );
}
function* createServiceFromProvider({ payload }) {
  const { data, onSuccess, onFailure } = payload;
  try {
    yield put(ServiceImportActions.setIsSubmitting(true));
    const response = yield call(
      ServiceImportRequest.createServiceFromProvider,
      data
    );
    if (response.success) {
      toast.success("Thêm dịch vụ thành công");
      onSuccess && onSuccess();
    } else {
      onFailure && onFailure();
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(ServiceImportActions.setError(error?.response?.data.message));
    onFailure && onFailure();
    toast.error(error?.response?.data.message);
  } finally {
    yield put(ServiceImportActions.setIsSubmitting(false));
  }
}

function* getAllServiceByProviderId({ payload }) {
  try {
    const { data } = payload;
    yield put(ServiceImportActions.setIsFetching(true));
    const response = yield call(
      ServiceImportRequest.getAllServiceByProviderId,
      { providerId: data?.providerId }
    );
    if (response.success) {
      const newData = formatGroupServices(response.data, keywords);
      yield put(ServiceImportActions.setServiceImportList(newData));
      const { search, platform } = yield select(ServiceImportSelectors.filter);
      const resultDataFilter = searchOrFilterPlatformGroupServices(
        newData,
        keywords,
        search || "",
        platform || ""
      );
      yield put(
        ServiceImportActions.setAllServiceFilterSearchOrPlatForm(resultDataFilter)
      );
    } else {
      yield put(
        ServiceImportActions.setAllServiceFilterSearchOrPlatForm({})
      );
      yield put(ServiceImportActions.setServiceImportList({}));
      throw new Error(response.message);
    }
  } catch (error) {
    yield put(
      ServiceImportActions.setAllServiceFilterSearchOrPlatForm({})
    );
    yield put(ServiceImportActions.setServiceImportList({}));
    yield put(ServiceImportActions.setError(error?.response?.data.message));
    toast.error(error?.response?.data.message);
  } finally {
    yield put(ServiceImportActions.setIsFetching(false));
  }
}

function* getAllServiceFilterSearchOrPlatForm({ payload }) {
  try {
    yield put(ServiceImportActions.setIsFetching(true));
    const listServiceImport = yield select(
      ServiceImportSelectors.serviceImportList
    );
    const { search, platform } = yield select(ServiceImportSelectors.filter);
    const newData = searchOrFilterPlatformGroupServices(
      listServiceImport,
      keywords,
      search,
      platform
    );
    yield put(
      ServiceImportActions.setAllServiceFilterSearchOrPlatForm(newData)
    );
  } catch (error) {
    yield put(ServiceImportActions.setAllServiceFilterSearchOrPlatForm({}));
    yield put(ServiceImportActions.setError(error?.response?.data.message));
    toast.error(error?.response?.data.message);
  } finally {
    yield put(ServiceImportActions.setIsFetching(false));
  }
}
function formatGroupServices(services, keywords) {
  const filteredServices = services.map((service) => {
    const platformGroup = findKeyword(
      [
        service?.platform || "",
        service?.name || "",
        service?.category || "",
        service?.description || "",
      ],
      keywords
    );
    return { ...service, platformGroup };
  });

  const groupedServices = filteredServices.reduce((acc, service) => {
    const group = service.platformGroup || "other";
    if (!acc[group]) acc[group] = [];
    acc[group].push(service);
    return acc;
  }, {});

  return groupedServices;
}
function searchOrFilterPlatformGroupServices(services, keywords, searchTerm = "", platform = "") {
  const normalizedSearchTerm = searchTerm ? normalizeText(searchTerm.toLowerCase()) : "";
  const result = {};
  const platforms = platform && services[platform] ? { [platform]: services[platform] } : services;
  for (const [platformKey, platformServices] of Object.entries(platforms)) {
    if (!Array.isArray(platformServices)) continue;
    const filteredServices = platformServices
      .filter((service) => {
        if (!normalizedSearchTerm) return true;
        const fields = [
          normalizeText(service?.platformGroup || "").toLowerCase(),
          normalizeText(service?.name || "").toLowerCase(),
          normalizeText(service?.service?.toString() || "").toLowerCase(),
        ];      
        return fields.some((field) => field.includes(normalizedSearchTerm));
      })
      .map((service) => {
        const platformGroup = findKeyword([service?.platformGroup], keywords);
        return { ...service, platformGroup };
      });

    if (filteredServices.length > 0) {
      result[platformKey] = filteredServices;
    }
  }
  return result;
}





