export function formatUSD(number) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(number));
}
export function shortenContent(content, maxLength = 40) {
  if (content?.length > maxLength) {
    return content.substring(0, maxLength) + "...";
  } else {
    return content;
  }
}
export const formatPriceVND = (data) => {
  try {
    const config = {
      style: "currency",
      currency: "VND",
      maximumFractionDigits: 9,
    };
    const price = new Intl.NumberFormat("vi-VN", config)
      .format(data || 0)
      .slice(0, -1);
    return price;
  } catch (error) {
    console.log(error);
    return data;
  }
};
export function isValidPath(basePath, inputPath) {
  const dynamicRegex = basePath
    .replace(/:[^\s/]+/g, "[^/]+")
    .replace(/\/$/, "");
  const regex = new RegExp(`${dynamicRegex}`);
  return regex.test(inputPath);
}
export const normalizeText = (text) =>
  text
    .normalize("NFKD")
    .replace(/[\u{1D400}-\u{1D7FF}]/gu, (c) =>
      String.fromCharCode(c.codePointAt(0) - 0x1d400 + 0x41)
    );

export const findKeyword = (inputArray, keywords) => {
  const normalizedInput = inputArray?.map((str) => {
    return normalizeText(str?.toLowerCase());
  });
  const normalizedKeywords = keywords?.map((keyword) =>
    normalizeText(keyword?.toLowerCase())
  );
  for (const text of normalizedInput) {
    for (const keyword of normalizedKeywords) {
      if (text?.includes(keyword)) {
        return keyword;
      }
    }
  }
  return "other";
};
